import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import TextOverlay from './textOverlay';
import withImageGradient from '../hocs/withImageGradient';
import GetConsultation from './getConsulation';
import homeInsurance from '../assets/home-insurance.svg';
import promotionSVG from '../assets/promotion.svg';
import mortgageSVG from '../assets/mortgage.svg';
import agentSVG from '../assets/agent.svg';
import valueSVG from '../assets/value.svg';
import planningSVG from '../assets/planning.svg';

const Item = ( {iconSrc, children, ...rest} ) => (
  <div className="w-full 2xl:w-auto flex items-center justify-center bg-white h-100px rounded-20px primary-shadow-15 whitespace-nowrap my-5 md:mx-1 lg:mx-25px py-40px lg:px-35px" {...rest}>
    <img
      alt=""
      src={iconSrc}
      className="w-50px h-50px m-2"
    />
    <div className="m-2 whitespace-normal">{children}</div>
  </div>
)

const Items = props => (
  <div {...props}>
    <Item iconSrc={homeInsurance}>100% безопасность сделки</Item>
    <Item iconSrc={promotionSVG}>эксклюзивные возможности по рекламе</Item>
    <Item iconSrc={mortgageSVG}>грамотное юридическое сопровождение</Item>
    <Item iconSrc={agentSVG}>оперативное исполнение</Item>
    <Item iconSrc={valueSVG}>максимальная рыночная стоимость</Item>
    <Item iconSrc={planningSVG}>индивидуальная схема работы</Item>
  </div>
)


const Photo = withImageGradient(() => (
  <StaticImage
    src="../images/why.jpg"
    quality={95}
    formats={["auto", "webp", "avif"]}
    className="rounded-20px"
    imgClassName="rounded-20px"
    alt="Светлана Беляева"
  />
));

const Advantages = () => (
  <div className="mt-60px mb-20">
    <TextOverlay name="advantages" text="Почему выбирают"/>
    <div className="container flex flex-wrap px-17px">
      <div className="flex flex-col w-full lg:w-1/2 2xl:w-3/4 order-2 lg:order-1">
        <Items
          className="advantages-items flex flex-wrap bg-no-repeat px-0px lg:px-25px h-auto 2xl:h-530px"
        />
        <GetConsultation className="self-start lg:mx-50px 2xl:self-end px-46px mt-40px lg:mt-80px mr-0px 2xl:mr-170px">Хочу стать клиентом</GetConsultation>
      </div>
      <div className="w-full lg:w-1/2 2xl:w-1/4 order-1 2xl:order-2">
        <Photo />
      </div>
    </div>
  </div>
);

export default Advantages;
