import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import TextOverlay from './textOverlay';
import GetConsultation from './getConsulation';
import withImageGradient from '../hocs/withImageGradient';

const BuildingImage = withImageGradient(() => (
  <StaticImage
    src="../images/building.jpg"
    quality={95}
    formats={["auto", "webp", "avif"]}
    className="rounded-20px"
    imgClassName="rounded-20px"
    alt="Дом"
  />
));

const Building = props => (
  <div {...props}>
    <BuildingImage />
  </div>
)


const Item = props => (
  <li
    className="my-4 flex ordered-list-item text-16px font-light items-center"
    {...props}
  />
)

const Description = props => (
  <div {...props}>
    <ol>
      <Item>Эффективно работаю в сфере недвижимости более 5 лет. Имею сертификацию. Работаю по городу Минску и Минской области.</Item>
      <Item>Заряжаю своей энергетикой, внимательная, быстрая и с серьезным отношением к делу. Люблю свою работу и позитивно настроена к людям. Наполняюсь после каждой сделки, когда вижу счастливые лица клиентов. Стараюсь для клиентов как для самой себя, моим клиентам достается только самое лучшее.</Item>
      <Item>Мои клиенты получают максимальный охват по рекламе не только на сайтах по продаже недвижимости, но и в социальных сетях.</Item>
    </ol>
    <GetConsultation className="px-46px -mr-30px md:mr-0 w-full sm:w-auto">Заказать звонок</GetConsultation>
  </div>
)

const AboutMe = () => (
  <div className="mt-60px">
    <TextOverlay name="about-me" text="Я профессионал"/>
    <div className="container flex flex-wrap mt-30px px-17px">
      <Building className="w-full lg:w-1/2 -mx-30px px-0px sm:px-30px" />
      <Description className="w-full lg:w-1/2 -mx-30px px-0px sm:px-30px" />
    </div>
  </div>
);

export default AboutMe;
