import React from 'react'

import Layout from '../components/layout'
import InstagramBlog from '../components/instagramBlog';
import AboutMe from '../components/aboutMe';
import Reviews from '../components/reviews';
import Advantages from '../components/advantages';
import Seo from "../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo title="Светлана Беляева — агент 007 в сфере недвижимости" />
    <InstagramBlog />
    <AboutMe />
    <Reviews />
    <Advantages />
  </Layout>
)

export default IndexPage
