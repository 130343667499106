import React from 'react';
import horizontalDotsSVG from '../assets/horizontalDots.svg';
import mergeClassNames from '../helpers/mergeClassNames';
import LinkWithGradient from './linkWithGradient';

const InstagramLink = ({ wrapperClassName }) =>(
  <div className={mergeClassNames("w-340px h-235px instagram-rectangle pt-60px pl-40px pr-50px text-right primary-bg-gradient flex flex-col items-center", wrapperClassName)}>
    <div>Подробнее о недвижимости рассказываю в своем блоге</div>
    <LinkWithGradient href="https://www.instagram.com/realt.bel/">Instagram</LinkWithGradient>
  </div>
)

const InstagramBlog = () => (
  <div className="flex flex-wrap mt-0px md:mt-100px lg:mt-550px xl:mt-350px 2xl:mt-200px relative">
    <img
      className="hidden lg:block absolute left-0 top-1/2"
      src={horizontalDotsSVG}
      alt=""
    />
    <div className="container">
      <InstagramLink wrapperClassName="ml-auto" />
    </div>
  </div>
);

export default InstagramBlog;
